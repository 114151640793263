import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-exam-layout',
  templateUrl: './exam-layout.component.html',
  styleUrls: ['./exam-layout.component.scss']
})
export class ExamLayoutComponent {

	@Input() public includeQuestionSideBar: boolean = true;
	@Input() public flex: boolean = false;

}
