import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ProtractorService {
	private protractorSizeRatioSource: Subject<number> = new Subject<number>();
	public protractorSizeRatioChange$ = this.protractorSizeRatioSource.asObservable();

    constructor() { }

    public updateProtractorSizeRatio(sizeRatio: number): void {
        this.protractorSizeRatioSource.next(sizeRatio);
    }
}