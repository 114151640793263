<mat-dialog-content>
    <div class="row">
        <div class="col text-center">
            <h1>Are you sure you want to finish your exam?</h1>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center">
            <button mat-raised-button class="tbp me-2" (click)="goBackToExam()">Back To Exam</button><br>
            <button mat-raised-button class="tbp" (click)="endExam()">End Exam</button><br>
        </div>
    </div>
</mat-dialog-content>