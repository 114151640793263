import { Injectable, Inject } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { of, throwError, Observable, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthorisationService } from '../authorisation/authorisation.service';

class Subscription {
  public method!: string;
  public callback!: Function;
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private candidateHubConnection!: signalR.HubConnection;

  constructor(@Inject('signalRBaseUrl') private signalRBaseUrl:string,
              private authorisationService: AuthorisationService) { }

  public startCandidateConnection(): Observable<string | void> {
    if (!this.candidateHubConnection) {
      this.candidateHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.signalRBaseUrl}/candidate`, {
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true
      })
      .withAutomaticReconnect()
      .build();

      return from(this.candidateHubConnection.start())
              .pipe(mergeMap(() => from(this.candidateHubConnection.invoke('Initialise', this.authorisationService.getAccessToken()))))
              .pipe(catchError(val => of('Unable to connect to signalr!')));
    } else {
      return of();
    }
  }

  public subscribe(method: string, callback: (...params: any) => any): void {
    if (this.candidateHubConnection) {
      this.candidateHubConnection.off(method);
      this.candidateHubConnection.on(method, callback);
    }
  }
}
