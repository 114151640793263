import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import { CandidateQuestionDto } from '../../../../model/exam/candidate-question-dto';
import { QuestionStateDto } from '../../../../model/exam/state/question-state-dto';
import { ExamNavigationService } from '../../../../service/exam-navigation.service';
import { CandidateQuestionDataService } from '../../../../database/services/candidate-question-data.service';
import { TimeService } from '../../../../service/time.service';

@Component({
	selector: 'app-section-modal',
	templateUrl: './section-modal.component.html',
	styleUrls: ['./section-modal.component.scss']
})
export class SectionModalComponent implements OnInit {
	public questions!: { flagged: CandidateQuestionDto[], nonAttempted: CandidateQuestionDto[] };

	constructor(private router: Router,
		private candidateQuestionDataService: CandidateQuestionDataService,
		private timeService: TimeService,
		private dialogRef: MatDialogRef<SectionModalComponent>,
		private examNavigationService: ExamNavigationService) { }

	public ngOnInit(): void {

		const flaggedQuestions: Observable<CandidateQuestionDto[]> = this.candidateQuestionDataService.getCurrentSectionFlaggedQuestions();
		const nonAttemptedQuestions: Observable<CandidateQuestionDto[]> = this.candidateQuestionDataService.getCurrentSectionNonAttemptedQuestions();

		forkJoin({
			flagged: flaggedQuestions,
			nonAttempted: nonAttemptedQuestions
		})
		.pipe(map((questions: { flagged: CandidateQuestionDto[], nonAttempted: CandidateQuestionDto[] }) => {
			this.questions = questions;
		}))
		.subscribe();
	}

	public close(): void {
		this.dialogRef.close();
	}

	public navigateSection(): void {
		this.close();
		this.timeService.nextBlock().subscribe((url: string) => {
			if (url) {
				this.router.navigate([url]);
			}
		});
	}

	public goToQuestion(questionNumber: number): void {
		this.candidateQuestionDataService.getQuestion(questionNumber)
		.pipe(mergeMap((questionState: QuestionStateDto) => this.examNavigationService.updateCurrentQuestion(questionState))).subscribe(() => {
			this.close();
		});
	}
}
