import { Component, OnInit } from '@angular/core';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { ArrayHelperService } from '../../../../common/services/array.service';
import { CandidateQuestionDataService } from '../../../../database/services/candidate-question-data.service';
import { QuestionAnswerDataService } from '../../../../database/services/question-answer-data.service';
import { CandidateQuestionDto } from '../../../../model/exam/candidate-question-dto';
import { QuestionStateDto } from '../../../../model/exam/state/question-state-dto';
import { ExamNavigationService } from '../../../../service/exam-navigation.service';
import { QuestionSectionStateDto } from '../../../../model/exam/state/question-section-state-dto';
import { DashboardService } from '../../../../service/dashboard.service';
import { ProgressService } from '../../../../service/progress.service';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

	public totalMarks: number = 0;
	public attemptedMarks: number = 0;
	public attemptedQuestionIds: string[] = [];
	public complete: boolean = false;
	public previousQuestion!: QuestionStateDto;
	public currentQuestionChangeSub!: Subscription;

	public sections: QuestionSectionStateDto[] = [];

	constructor(private examNavigationService: ExamNavigationService,
		private questionAnswerDataService: QuestionAnswerDataService,
		private candidateQuestionDataService: CandidateQuestionDataService,
		private progressService: ProgressService,
		private dashboardService: DashboardService) { }

	public ngOnInit(): void {

		this.examNavigationService.getQuestionSectionsWithCandidateQuestions()
			.pipe(first())
			.pipe(map((qsq: QuestionSectionStateDto[]) => qsq.map(x => x.questionStates)))
			.pipe(map((qs: QuestionStateDto[][]) => ArrayHelperService.flatten(qs).map((x) => x.question.potentialScore ?? 0)))
			.pipe(map((scores: number[]) => {
				this.totalMarks = scores.reduce((a, b) => a + b);
			}))
			.pipe(mergeMap(() => this.updateProgress()))
			.subscribe();

		this.currentQuestionChangeSub = this.examNavigationService.currentQuestionChange$
			.pipe(mergeMap(() => this.updateProgress()))
			.pipe(mergeMap(() => this.dashboardService.sendQuestionsAnsweredNotification(this.attemptedQuestionIds.length)))
			.subscribe();
	}

	public calculateProgress(): string {

		if (this.attemptedMarks && this.totalMarks) {
			const percentage = (this.attemptedMarks / this.totalMarks) * 100;

			if (percentage >= 100) {
				this.complete = true;
			} else {
				this.complete = false;
			}

			this.progressService.isComplete = this.complete;
			return percentage.toFixed(0);
		}

		return '';
	}

	private updateProgress(): Observable<void> {

		return this.questionAnswerDataService.getAllAnswers()
			.pipe(mergeMap((val) => of(val.filter(x => x.answer.answered()))))
			.pipe(mergeMap((val) => this.candidateQuestionDataService.getQuestionsById(val.map(x => x.candidateQuestionId))))
			.pipe(map((val) => {
				val.forEach((question: CandidateQuestionDto) => {
					if (!this.attemptedQuestionIds.includes(question.id)) {
						this.attemptedMarks += question.potentialScore ?? 0;
						this.attemptedQuestionIds.push(question.id);
					}
				});
			}));
	}
}
