<ng-content select="[header]"></ng-content>
<div id="examBody" class="row content-body">
  <ng-content select="[tool]"></ng-content>
  @if (includeQuestionSideBar) {
    <div class="item-list-container">
      <ng-content select="[item-selection]"></ng-content>
    </div>
  }
  <div class="col" [ngClass]="{'d-flex': flex }">
    <ng-content select="[top-content]"></ng-content>
    <ng-content select="[bottom-content]"></ng-content>
  </div>
</div>
<ng-content select="[scroll-to]"></ng-content>
<ng-content select="[footer]"></ng-content>
