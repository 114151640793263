import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ExamSectionTimeDto } from '../model/exam/exam-section-time-dto';
import { CandidateRequestService } from './request/candidate-request.service';

@Injectable({
	providedIn: 'root'
})
export class CandidateExamSectionService {

	constructor(private candidateRequestService: CandidateRequestService) { }

	public startSection(start: ExamSectionTimeDto): Observable<ExamSectionTimeDto> {
		return this.candidateRequestService.put<ExamSectionTimeDto>("candidate/exam/section/start", start)
			.pipe(mergeMap((val) => {
				return of(val?.responseData)
			}));
	}

	public endSection(end: ExamSectionTimeDto): Observable<ExamSectionTimeDto> {
		return this.candidateRequestService.put<ExamSectionTimeDto>("candidate/exam/section/end", end)
			.pipe(mergeMap((val) => {
				return of(val?.responseData)
			}));
	}
}
