import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, of, BehaviorSubject } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { CandidateQuestionDataService } from '../database/services/candidate-question-data.service';
import { KeyValueDataService } from '../database/services/key-value-data.service';
import { CandidateQuestionDto } from '../model/exam/candidate-question-dto';
import { QuestionStateDto } from '../model/exam/state/question-state-dto';
import { CandidateExamStateDto } from '../model/exam/state/candidate-exam-state-dto';
import { QuestionSectionStateDto } from '../model/exam/state/question-section-state-dto';
import { QuestionSectionDataService } from '../database/services/question-section-data.service';
import { QuestionSectionDto } from '../model/exam/question-section-dto';
import { QuestionAnswerStateDTO } from '../model/exam/state/question-answer-state-dto';

@Injectable({
	providedIn: 'root'
})
export class MockExamNavigationService {

	public examState: CandidateExamStateDto = new CandidateExamStateDto();
	public currentQuestionIndex: number = 0;

	private currentQuestionSource: Subject<QuestionStateDto> = new Subject<QuestionStateDto>();
	public currentQuestionChange$ = this.currentQuestionSource.asObservable();

	private flaggedQuestionsSource: Subject<number[]> = new Subject<number[]>();
	public flaggedQuestionsChange$ = this.flaggedQuestionsSource.asObservable();

	private answeredQuestionsSource: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	public answeredQuestionsChange$ = this.answeredQuestionsSource.asObservable();

	constructor(private keyValueDataService: KeyValueDataService,
				private candidateQuestionDataService: CandidateQuestionDataService,
				private questionSectionDataService: QuestionSectionDataService) { }

	public getSubjectName(): Observable<string> {
		return this.keyValueDataService.getSubjectName();
	}

	public getScheduledFinish(): Observable<Date> {
		return this.keyValueDataService.getScheduledFinish();
	}

	public getCurrentQuestion(): Observable<QuestionStateDto> {
		return this.candidateQuestionDataService.getCurrentQuestionState();
	}

	public goToQuestion(questionNumber: number): Observable<boolean> {
        return this.candidateQuestionDataService.getQuestionByOrderIndex(questionNumber)
			.pipe(mergeMap((candidateQuestionDto: CandidateQuestionDto) => this.goToQuestionOrSectionModal(candidateQuestionDto)));
	}

	public goToNextQuestion(): Observable<boolean> {
        return this.candidateQuestionDataService.getCurrentQuestionState()
			.pipe(mergeMap((questionState: QuestionStateDto) => this.candidateQuestionDataService.getQuestionByOrderIndex(questionState.question.orderIndex + 1)))
			.pipe(mergeMap((candidateQuestionDto: CandidateQuestionDto) => this.goToQuestionOrSectionModal(candidateQuestionDto)));
	}

	public goToPreviousQuestion(): Observable<boolean> {
        return this.candidateQuestionDataService.getCurrentQuestionState()
			.pipe(mergeMap((questionState: QuestionStateDto) => this.candidateQuestionDataService.getQuestionByOrderIndex(questionState.question.orderIndex - 1)))
			.pipe(mergeMap((candidateQuestionDto: CandidateQuestionDto) => this.goToQuestionOrSectionModal(candidateQuestionDto)));
	}

	public getQuestionCount(): Observable<CandidateQuestionDto[]> {
		return this.candidateQuestionDataService.getAllQuestions();
	}

	public getQuestionSectionsWithCandidateQuestions(): Observable<QuestionSectionStateDto[]> {
		return this.questionSectionDataService.getSectionQuestions();
	}

	public flagCurrentQuestion(): void {
		this.candidateQuestionDataService.flagCurrentQuestion()
			.pipe(mergeMap(() => this.getFlaggedQuestionNumbers()))
			.pipe(map((flaggedNumbers: number[]) => this.flaggedQuestionsSource.next(flaggedNumbers)))
			.pipe(first())
			.subscribe();
	}

	public setAnsweredCurrentQuestion(questionState: QuestionStateDto, questionAnswerStates: QuestionAnswerStateDTO[]): void {
		let answeredQuestions: number[] = this.answeredQuestionsSource.value;

		let previouslyAnswered = false;
		answeredQuestions.forEach(answeredQuestionIndex => {
			if (answeredQuestionIndex === questionState.question.orderIndex) previouslyAnswered = true;
		});

		if (!previouslyAnswered) {

			let newResponses = questionAnswerStates.filter(x => x.candidateQuestionId === questionState.question.id);
			let questionAnswered: boolean = false;
			newResponses.forEach(response => {
				if (response.answer.answered()) {
					questionAnswered = true;
				}
			});

			if (questionAnswered) answeredQuestions.push(questionState.question.orderIndex);
		}

		this.answeredQuestionsSource.next(answeredQuestions)
	}

	public getAnsweredQuestionNumbers(): Observable<number[]> {
		return this.answeredQuestionsSource;
	}

	public getFlaggedQuestionNumbers(): Observable<number[]> {
		return this.candidateQuestionDataService.getFlaggedQuestionNumbers();
	}

	public getLastQuestionIndex(): Observable<number> {
		return this.candidateQuestionDataService.getAllQuestions()
			.pipe(map((candidateQuestions: CandidateQuestionDto[]) => {
				if (!candidateQuestions.length) {
					return -1;
				}
				const index = candidateQuestions.sort((q1, q2) => q1.orderIndex - q2.orderIndex)[candidateQuestions.length - 1].orderIndex;
				return index;
			}));
	}

	public updateCurrentQuestion(question: QuestionStateDto): Observable<void> {
		return this.keyValueDataService.setCurrentQuestionNumber(question.question.orderIndex)
			.pipe(map(() => this.currentQuestionSource.next(question)));
	}

	private goToQuestionOrSectionModal(question: CandidateQuestionDto): Observable<boolean> {

		const questionSection = this.questionSectionDataService.get(question.sectionId);
		const currentSection = this.keyValueDataService.getCurrentSectionNumber();

		return forkJoin([questionSection, currentSection])
			.pipe(mergeMap((value: [QuestionSectionDto, number]) => {

				if (value[0].orderIndex < value[1]) {
					return of(false);
				}

				if (value[0].orderIndex === value[1]) {
					return of(true);
				}

				if (value[0].orderIndex > value[1]) {
					return of(false);
				}

				return of(true);
			}));
	}
}
