import { Injectable } from "@angular/core";
import { ITimedBlock } from "../../model/exam/timed-block-interface";
import { AppDatabase } from "../app.database";
import { TimedBlockEntity } from "../entities/timed-block-entity";
import { BaseRepository } from "./base-repository";

@Injectable({
	providedIn: 'root'
})
export class TimedBlockRepository extends BaseRepository<TimedBlockEntity, string, ITimedBlock>
{
	constructor(protected override db: AppDatabase) {
		super(db, 'timedBlocks');
	}

    public convertDto(entity: TimedBlockEntity): ITimedBlock {
		return {
			index: entity.index,
			sectionId: entity.sectionId,
			type: entity.type
		} as ITimedBlock;
    }
    public convertEntity(dto: ITimedBlock): TimedBlockEntity {
		return {
			index: dto.index,
			sectionId: dto.sectionId,
			type: dto.type
		}
    }
}
