import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EndConfirmComponent } from '../site/exam/end/end-confirm/end-confirm.component';
import { SectionModalComponent } from '../site/exam/section/section-modal/section-modal.component';
import { MockSectionModalComponent } from '../site/mock/section/section-modal/section-modal.component';
import { ThemeSelectorModalComponent } from '../site/theme/theme-selector-modal/theme-selector-modal.component';

@Injectable({
	providedIn: 'root'
})
export class DialogService {

	private themeDialogRef: MatDialogRef<ThemeSelectorModalComponent> | null= null;
	private sectionDialogRef: MatDialogRef<SectionModalComponent> | null= null;
	private mockSectionDialogRef: MatDialogRef<MockSectionModalComponent>| null = null;
	private endExamDialogRef: MatDialogRef<EndConfirmComponent> | null= null;

	constructor(private dialog: MatDialog) { }

	public showThemeSelectionModal(): void {
		if (!this.themeDialogRef) {
			this.themeDialogRef = this.dialog.open(ThemeSelectorModalComponent, {
				width: '50rem',
				hasBackdrop: false,
				panelClass: ['mbg', 'fd']
			});
		}

		this.themeDialogRef.afterClosed().subscribe(() => {
			this.themeDialogRef = null;
		});

	}

	public showSectionModal(): void {
		if (!this.sectionDialogRef) {
			this.sectionDialogRef = this.dialog.open(SectionModalComponent, {
				width: '50rem',
				hasBackdrop: false,
				panelClass: ['mbg', 'fd']
			});
		}

		this.sectionDialogRef.afterClosed().subscribe(() => {
			this.sectionDialogRef = null;
		});
	}

	public showMockSectionModal(): void {
		if (!this.mockSectionDialogRef) {
			this.mockSectionDialogRef = this.dialog.open(MockSectionModalComponent, {
				width: '50rem',
				hasBackdrop: false,
				panelClass: ['mbg', 'fd']
			});
		}

		this.mockSectionDialogRef.afterClosed().subscribe(() => {
			this.mockSectionDialogRef = null;
		});
	}

	public hideSectionModal(): void {

		if (this.sectionDialogRef) {
			this.sectionDialogRef.close();
		}
	}

	public showConfirmEndExamDialog(): void {
		if (!this.endExamDialogRef) {
			this.endExamDialogRef = this.dialog.open(EndConfirmComponent, {
				width: '50rem',
				hasBackdrop: true,
				panelClass: ['mbg', 'fd']
			});
		}

		this.endExamDialogRef.afterClosed().subscribe(() => {
			this.endExamDialogRef = null;
		});
	}
}
