import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ExamSectionBreakDto } from '../model/exam/exam-section-break-dto';
import { ExamSectionBreakResponseDto } from '../model/exam/exam-section-break-response-dto';
import { QuestionSectionDto } from '../model/exam/question-section-dto';
import { APIResponse } from '../model/request/APIResponse';
import { CandidateRequestService } from './request/candidate-request.service';

@Injectable({
	providedIn: 'root'
})
export class CandidateExamBreakService {

	constructor(private candidateRequestService: CandidateRequestService) { }

	public startBreak(start: ExamSectionBreakDto): Observable<ExamSectionBreakResponseDto> {
		return this.candidateRequestService.put<ExamSectionBreakResponseDto>('candidate/exam/breaks/start', start)
			.pipe(mergeMap((val) => {
				return of(val?.responseData);
			}));
	}

	public endBreak(section: QuestionSectionDto): Observable<APIResponse<void>> {
		return this.candidateRequestService.put('candidate/exam/breaks/end', section);
	}
}
