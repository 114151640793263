import { Injectable } from '@angular/core';
import { from, iif, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { QuestionAnswerStateDTO } from '../../model/exam/state/question-answer-state-dto';
import { ExamService } from '../../service/exam.service';
import { CandidateQuestionEntity } from '../entities/candidate-question-entity';
import { QuestionAnswerEntity } from '../entities/question-answer-entity';
import { CandidateQuestionRepositoryService } from '../repositories/candidate-question-repository.service';
import { QuestionAnswerRepositoryService } from '../repositories/question-answer-repository.service';
import { APIResponse } from '../../model/request/APIResponse';
import { ConnectionService } from '../../service/connection.service';
import { SynchroniseService } from '../../service/synchronise.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionAnswerDataService {
    constructor(private questionAnswerRepository: QuestionAnswerRepositoryService,
                private candidateQuestionRepository: CandidateQuestionRepositoryService,
                private examService: ExamService,
                private connectionService: ConnectionService,
                private synchroniseService: SynchroniseService) { }

    public toQuestionAnswerStateDto(questionAnswerEntity: QuestionAnswerEntity): QuestionAnswerStateDTO {
        return {
            id: questionAnswerEntity.questionAnswerId,
            candidateQuestionId: questionAnswerEntity.candidateQuestionId,
            answerTypeId: questionAnswerEntity.answerTypeId,
            potentialScore: questionAnswerEntity.potentialScore,
            answer: questionAnswerEntity.answer,
            layout: questionAnswerEntity.layout,
			layoutTypeIdentifier: questionAnswerEntity.layoutTypeIdentifier,
			labelText: questionAnswerEntity.labelText,
			labelAlignmentIdentifier: questionAnswerEntity.labelAlignmentIdentifier,
			labelPositionIdentifier: questionAnswerEntity.labelPositionIdentifier
        };
	}

	public getAllAnswers(): Observable<QuestionAnswerStateDTO[]> {
		return this.questionAnswerRepository.getAll()
			.pipe(map((val) => val.map(this.questionAnswerRepository.convertDto)));
	}

	public getAnswersForQuestion(questionId: string): Observable<QuestionAnswerStateDTO[]> {
		return this.questionAnswerRepository.getMultiple('candidateQuestionId', questionId)
			.pipe(map((val) => val.map(this.questionAnswerRepository.convertDto)));
	}

	public saveAnswers(questionAnswers: QuestionAnswerStateDTO[]): Observable<APIResponse<void>> {
        let tempCandidateQuestion: CandidateQuestionEntity;
        return this.candidateQuestionRepository.get('candidateQuestionId', questionAnswers[0].candidateQuestionId)
			.pipe(map((candidateQuestionEntity: CandidateQuestionEntity) => {
				if (questionAnswers.filter(x => x.answer.answered()).length > 0) {
					candidateQuestionEntity.responseCount++;
				}

                    return candidateQuestionEntity;
                }))
                .pipe(mergeMap((candidateQuestionEntity: CandidateQuestionEntity) => {
                    return this.candidateQuestionRepository.put(candidateQuestionEntity)
                        .pipe(switchMap(() => {
                            tempCandidateQuestion = candidateQuestionEntity;
                            return of(candidateQuestionEntity);
                        }));
                    }))
                .pipe(mergeMap(() => from(questionAnswers.map(this.questionAnswerRepository.convertEntity))))
                .pipe(mergeMap((questionAnswerEntity: QuestionAnswerEntity) => this.questionAnswerRepository.put(questionAnswerEntity)))
			.pipe(switchMap(() => this.synchroniseService.calculateChecksum()))
			.pipe(switchMap(() => iif(() => this.connectionService.isOnline(), this.examService.saveAnswers(questionAnswers, this.candidateQuestionRepository.convertDto(tempCandidateQuestion)), of())));
    }

    public saveMockAnswers(questionAnswers: QuestionAnswerStateDTO[]): Observable<void> {
        return this.candidateQuestionRepository.get('candidateQuestionId', questionAnswers[0].candidateQuestionId)
			.pipe(map((candidateQuestionEntity: CandidateQuestionEntity) => {
				if (questionAnswers.filter(x => x.answer.answered()).length > 0) {
					candidateQuestionEntity.responseCount++;
				}
				return candidateQuestionEntity;
			}))
                .pipe(mergeMap((candidateQuestionEntity: CandidateQuestionEntity) => {
                    return this.candidateQuestionRepository.put(candidateQuestionEntity)
                        .pipe(switchMap(() => {
                            return of(candidateQuestionEntity);
                        }));
                    }))
                .pipe(mergeMap(() => from(questionAnswers.map(this.questionAnswerRepository.convertEntity))))
                .pipe(mergeMap((questionAnswerEntity: QuestionAnswerEntity) => this.questionAnswerRepository.put(questionAnswerEntity)));
    }

    
}
