import { Injectable } from '@angular/core';
import { forkJoin, from, iif, Observable, of, Subject } from 'rxjs';
import { first, map, mergeMap, switchMap, tap, catchError } from 'rxjs/operators';
import { ArrayHelperService } from '../common/services/array.service';
import { CandidateQuestionDataService } from '../database/services/candidate-question-data.service';
import { KeyValueDataService } from '../database/services/key-value-data.service';
import { QuestionSectionDataService } from '../database/services/question-section-data.service';
import { TimedBlockDataService } from '../database/services/timed-block.service';
import { CandidateQuestionDto } from '../model/exam/candidate-question-dto';
import { ExamSectionBreakResponseDto } from '../model/exam/exam-section-break-response-dto';
import { ExamSectionTimeDto } from '../model/exam/exam-section-time-dto';
import { QuestionSectionDto } from '../model/exam/question-section-dto';
import { QuestionStateDto } from '../model/exam/state/question-state-dto';
import { ITimedBlock } from '../model/exam/timed-block-interface';
import { TimerType } from '../model/exam/timer-type.enum';
import { AutoSaveService } from './autosave.service';
import { CandidateExamBreakService } from './candidate-exam-break.service';
import { CandidateExamSectionService } from './candidate-exam-section.service';
import { ConnectionService } from './connection.service';
import { ExamNavigationService } from './exam-navigation.service';
import { LockoutService } from './lockout.service';
import { MockExamNavigationService } from './mock-exam-navigation.service';
import { RequestService } from './request/request.service';

@Injectable({
	providedIn: 'root'
})
export class TimeService {

	public currentBreakDuration!: QuestionSectionDto;

	private stopTimerSource: Subject<void> = new Subject<void>();
	public stopTimerChange$ = this.stopTimerSource.asObservable();

	constructor(private questionSectionDataService: QuestionSectionDataService,
		private keyValueDataService: KeyValueDataService,
		private connectionService: ConnectionService,
		private candidateExamBreakService: CandidateExamBreakService,
		private candidateQuestionDataService: CandidateQuestionDataService,
		private candidateExamSectionService: CandidateExamSectionService,
		private examNavigationService: ExamNavigationService,
		private requestService: RequestService,
		private autoSaveService: AutoSaveService,
		private lockoutService: LockoutService,
		private blocksDataService: TimedBlockDataService,
		private mockExamNavigationService: MockExamNavigationService) {
	}

	public static convertUTCDateToLocalDate(date: Date): Date {
		return new Date(date);
	}

	public static addMinutesToDateTime(mins: number, dateTime: Date): Date {
		return new Date(new Date(dateTime).getTime() + mins * 60000);
	}

	public getRemainingDateTime(endTime: Date): Observable<Date> {
		return this.resolveCurrentTime()
			.pipe(map((currentDateTime: Date) => {
				return new Date(new Date(endTime).getTime() - new Date(currentDateTime).getTime());
			}));
	}

	public nextBlock(): Observable<string> {
		this.stopTimerSource.next();

		let blocks: ITimedBlock[];
		let currentBlock: ITimedBlock;
		let currentBlockIndex: number;

		return forkJoin({
			blocks: this.blocksDataService.getAllBlocks(),
			currentBlockIndex: this.keyValueDataService.getCurrentBlockIndex()
		})
		.pipe(map((val) => {
			blocks = val.blocks;
			currentBlock = val.blocks[val.currentBlockIndex];
			currentBlockIndex = val.currentBlockIndex;
		}))
		.pipe(mergeMap(() => iif(() => currentBlock && currentBlock.type === TimerType.Section, this.endCurrentSection(), of(null))
			.pipe(mergeMap(() => {
				++currentBlockIndex;
				return this.keyValueDataService.setCurrentBlock(currentBlockIndex);
			}))
			.pipe(switchMap(() => this.keyValueDataService.getMockFlag()))
			.pipe(switchMap((isMock: boolean) => {
				if (currentBlockIndex > blocks.length - 1) {
					this.autoSaveService.stopAutoSaving();
					this.lockoutService.stopBlurWatching();


					return of(`${this.getRouterSection(isMock)}/finish`);
				}

				const newblock = blocks[currentBlockIndex];

				if (newblock) {

					if (newblock.type === TimerType.Break) {
						return this.setInBreak(true)
							.pipe(mergeMap(() => this.startBreak()))
							.pipe(mergeMap(() => {
								this.autoSaveService.stopAutoSaving();
								this.lockoutService.stopBlurWatching();

								return of(`${this.getRouterSection(isMock)}/section`);
							}));
					}

					if (newblock.type === TimerType.Section) {
						return this.keyValueDataService.getCurrentSectionNumber()
							.pipe(mergeMap((currentSectionNumber: number) => {
								return this.questionSectionDataService.getBySectionNumber(currentSectionNumber)
									.pipe(mergeMap((currentSection: QuestionSectionDto) => iif(() => !!currentSection.breakDuration,
										this.endBreak()
											.pipe(map(() => currentSectionNumber)),
										of(currentSectionNumber))));
							}))
							.pipe(mergeMap((currentSection: number) => this.questionSectionDataService.getNextSection(currentSection)))
							.pipe(mergeMap((nextSection: QuestionSectionDto) => {
								return this.keyValueDataService.setCurrentSectionNumber(nextSection.orderIndex)
									.pipe(map(() => nextSection));
							}))
							.pipe(mergeMap((nextSection: QuestionSectionDto) => {
								return this.isOnlineAndNotMock()
									.pipe(mergeMap((isOnlineAndNotMock: boolean) => iif(() => isOnlineAndNotMock, this.handleNextSectionOnline(nextSection), this.handleNextSectionOffline(nextSection))));
							}))
							.pipe(mergeMap((nextSection: QuestionSectionDto) => {
								this.keyValueDataService.setCanNavigateAway(nextSection.canNavigateAway)

								if (nextSection.canNavigateAway) {
									this.lockoutService.stopBlurWatching();
								}

								return of(nextSection);
							}))
							.pipe(mergeMap((nextSection: QuestionSectionDto) => this.candidateQuestionDataService.getFirstQuestionFromSectionQuestion(nextSection.orderIndex)))
							.pipe(mergeMap((upcomingQuestion: CandidateQuestionDto) => this.candidateQuestionDataService.getQuestion(upcomingQuestion.orderIndex)))
							.pipe(mergeMap((questionState: QuestionStateDto) => !isMock ? this.examNavigationService.updateCurrentQuestion(questionState) : this.mockExamNavigationService.updateCurrentQuestion(questionState)))
							.pipe(mergeMap(() => this.setInBreak(false)))
							.pipe(map(() => this.getRouterSection(isMock)))
					}

					return of(null);
				}

				return of("");
			})))) as Observable<string>;
	}

	public endCurrentSection(): Observable<QuestionSectionDto> {
		return this.keyValueDataService.getCurrentSectionNumber()
			.pipe(first())
			.pipe(mergeMap((currentSection: number) => this.questionSectionDataService.getBySectionNumber(currentSection)))
			.pipe(mergeMap((currentsection: QuestionSectionDto) => {
				return this.isOnlineAndNotMock()
					.pipe(mergeMap((isOnlineAndNotMock: boolean) => iif(() => isOnlineAndNotMock,
					this.handleCurrentSectionOnline(currentsection),
					this.handleCurrentSectionOffline(currentsection))))
			}));
	}

	public getCurrentSectionTime(): Observable<Date> {
		return this.keyValueDataService.getInBreak()
			.pipe(mergeMap((inBreak: boolean) => iif(() => inBreak, this.getCurrentBreakFinish(), this.getCurrentSectionFinish())));
	}

	public getInBreak(): Observable<boolean> {
		return this.keyValueDataService.getInBreak();
	}

	public setInBreak(value: boolean): Observable<void> {
		return this.keyValueDataService.setInBreak(value);
	}

	public addTimeToCurrentSection(sections: QuestionSectionDto[]): Observable<Date> {
		return from(sections)
			.pipe(mergeMap((section: QuestionSectionDto) => this.questionSectionDataService.updateTimeAdjustment(section)))
			.pipe(switchMap(() => this.questionSectionDataService.getCurrentSection()))
			.pipe(map((currentSection: QuestionSectionDto) => {
				return TimeService.addMinutesToDateTime(this.getTotalDurationFromSection(currentSection), new Date(currentSection.sectionStarted!));
			}));
	}

	public resolveCurrentTime(): Observable<Date> {
		if (this.connectionService.isOnline()) {
			return this.requestService.get<Date>('time')
				.pipe(map(x => x.responseData ? x.responseData : new Date()));
		} else {
			return of(new Date());
		}
	}

	private startBreak(): Observable<void> {
		return this.keyValueDataService.getCurrentSectionNumber()
			.pipe(mergeMap((currentSection: number) => this.questionSectionDataService.getBySectionNumber(currentSection)))
			.pipe(mergeMap((currentSection: QuestionSectionDto) => {
				return this.isOnlineAndNotMock()
					.pipe(mergeMap((isOnlineAndNotMock: boolean) => iif(() => isOnlineAndNotMock, this.handleOnlineBreak(currentSection), this.handleOfflineBreak(currentSection))))
			}));
	}

	private handleOnlineBreak(currentSection: QuestionSectionDto): Observable<void> {
		return this.candidateExamBreakService.startBreak({ sectionId: currentSection.id, duration: currentSection.breakDuration! })
			.pipe(first())
			.pipe(mergeMap((breakTimes: ExamSectionBreakResponseDto) => {
				currentSection.breakStarted = breakTimes.started!;
				currentSection.breakEnded = breakTimes.ended!;
				this.currentBreakDuration = currentSection;
				return this.questionSectionDataService.put(currentSection);
			}));
	}

	private handleOfflineBreak(currentSection: QuestionSectionDto): Observable<void> {
		return this.resolveCurrentTime()
			.pipe(mergeMap((currentDateTime: Date) => {
				currentSection.breakStarted = new Date(currentDateTime);
				currentSection.breakEnded = TimeService.addMinutesToDateTime(currentSection.breakDuration!, currentSection.breakStarted);
				this.currentBreakDuration = currentSection;
				return this.questionSectionDataService.put(currentSection);
			}));
	}

	private handleCurrentSectionOnline(currentSection: QuestionSectionDto): Observable<QuestionSectionDto> {
		return this.candidateExamSectionService.endSection({ sectionId: currentSection.id, canNavigateAway: currentSection.canNavigateAway })
			.pipe(mergeMap((time: ExamSectionTimeDto) => {
				currentSection.sectionEnded = time.ended;
				return this.questionSectionDataService.put(currentSection);
			}))
			.pipe(mergeMap(() => of(currentSection)));
	}

	private handleCurrentSectionOffline(currentSection: QuestionSectionDto): Observable<QuestionSectionDto> {
		return this.resolveCurrentTime()
			.pipe(map((currentDateTime: Date) => {
				currentSection.sectionEnded = new Date(currentDateTime);
				return currentSection;
			})).pipe(mergeMap((updatedSection: QuestionSectionDto) => {
				return this.questionSectionDataService.put(updatedSection)
					.pipe(map(() => updatedSection));
			}));
	}

	private handleNextSectionOnline(nextSection: QuestionSectionDto): Observable<QuestionSectionDto> {
		return this.candidateExamSectionService.startSection({ sectionId: nextSection.id, canNavigateAway: nextSection.canNavigateAway })
			.pipe(mergeMap((time: ExamSectionTimeDto) => {
				nextSection.sectionStarted = time.started;
				nextSection.sectionEnded = time.ended;
				nextSection.canNavigateAway = time.canNavigateAway;
				return this.questionSectionDataService.put(nextSection);
			}))
			.pipe(mergeMap(() => of(nextSection)));
	}

	private handleNextSectionOffline(nextSection: QuestionSectionDto): Observable<QuestionSectionDto> {
		return this.resolveCurrentTime()
			.pipe(mergeMap((currentDateTime: Date) => {
				nextSection.sectionStarted = new Date(currentDateTime);
				nextSection.sectionEnded = TimeService.addMinutesToDateTime(nextSection.duration!, nextSection.sectionStarted);
				return this.questionSectionDataService.put(nextSection).pipe(map(() => nextSection));
			}));
	}

	private getCurrentSectionFinish(): Observable<Date> {
		return this.keyValueDataService.getCurrentSectionNumber()
			.pipe(mergeMap((currentSection: number) => this.questionSectionDataService.getBySectionNumber(currentSection)))
			.pipe(map((currentSection: QuestionSectionDto) => {
				return TimeService.addMinutesToDateTime(this.getTotalDurationFromSection(currentSection), currentSection.sectionStarted!);
			}));
	}

	private getCurrentBreakFinish(): Observable<Date> {
		return this.keyValueDataService.getCurrentSectionNumber()
			.pipe(mergeMap((currentSection: number) => this.questionSectionDataService.getBySectionNumber(currentSection)))
			.pipe(map((currentSection: QuestionSectionDto) => {
				return TimeService.addMinutesToDateTime(currentSection.breakDuration!, currentSection.breakStarted);
			}));
	}

	private endBreak(): Observable<void> {
		return this.keyValueDataService.getCurrentSectionNumber()
			.pipe(mergeMap((currentSection: number) => this.questionSectionDataService.getBySectionNumber(currentSection)))
		.pipe(mergeMap((currentSection: QuestionSectionDto) => {
			return this.isOnlineAndNotMock()
				.pipe(mergeMap((isOnlineAndNotMock: boolean) => iif(() => isOnlineAndNotMock, this.handleEndBreakOnline(currentSection), this.handleEndBreakOffline(currentSection))))
		}));
	}

	private handleEndBreakOnline(currentSection: QuestionSectionDto): Observable<void> {
		return this.resolveCurrentTime()
			.pipe(map((currentDateTime: Date) => {
				currentSection.breakEnded = currentDateTime;
				return currentSection;
			})).pipe(mergeMap((updatedSection: QuestionSectionDto) => {
				return this.candidateExamBreakService.endBreak(updatedSection)
					.pipe(map(() => updatedSection));
			})).pipe(mergeMap((updatedSection: QuestionSectionDto) => this.questionSectionDataService.put(updatedSection)));
	}

	private handleEndBreakOffline(currentSection: QuestionSectionDto): Observable<void> {
		return this.resolveCurrentTime()
			.pipe(map((currentDateTime: Date) => {
				currentSection.breakEnded = currentDateTime;
				return currentSection;
			})).pipe(mergeMap((updatedSection: QuestionSectionDto) => this.questionSectionDataService.put(updatedSection)));
	}

	private isOnlineAndNotMock(): Observable<boolean> {
		return this.keyValueDataService.getMockFlag()
			.pipe(map((isMock: boolean) => {
				return this.connectionService.isOnline() && !isMock;
			}));
	}

	private getRouterSection(isMock: boolean): string {
		if (isMock) {
			return 'mock';
		} else {
			return 'exam';
		}
	}

	private getTotalDurationFromSection(section: QuestionSectionDto): number {
		return section.duration! + (section.timeAdjustment ? section.timeAdjustment : 0) + (section.paused ? section.paused : 0);
	}
}
