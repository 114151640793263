import { retry } from "rxjs";

export class ArrayHelperService {

	public static sortByProperty(a: any, b: any, property: string): 1|0|-1 {
		if (typeof a[property] === 'string' || a[property] instanceof String) {
			return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : a[property].toLowerCase() === b[property].toLowerCase() ? 0 : -1;
		} else {
			return a[property] > b[property] ? 1 : a[property] === b[property] ? 0 : -1;
		}
	}

	public static flatten<T>(values: T[][]): T[] {
		return values.flat();
	}
}
