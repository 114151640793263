<mat-dialog-content>
<div class="container-fluid">
  <div class="row">
    <div class="col d-flex justify-content-end">
      <span class="material-icons clickable" (click)="close()">clear</span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-grid-list cols="2" rowHeight="8rem">
        @for (theme of themes; track theme) {
          @if (theme.canSelect) {
            <mat-grid-tile [colspan]="1" [rowspan]="1">
              <mat-card class="full-size-card clickable bs fs" (click)="setTheme(theme)">
                <mat-card-content>
                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <h4>{{ theme.name }}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-flex flex-direction-row">
                      <div class="small-box" [style.background]="theme.properties['--preview-one']"></div>
                      <div class="small-box" [style.background]="theme.properties['--preview-two']"></div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-grid-tile>
          }
        }
      </mat-grid-list>
    </div>
  </div>
</div>
</mat-dialog-content>