import { Injectable } from '@angular/core';
import { forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { ArrayHelperService } from '../common/services/array.service';
import { CandidateQuestionDataService } from '../database/services/candidate-question-data.service';
import { KeyValueDataService } from '../database/services/key-value-data.service';
import { QuestionSectionDataService } from '../database/services/question-section-data.service';
import { AnsweredQuestion } from '../model/exam/answered-question';
import { CandidateQuestionDto } from '../model/exam/candidate-question-dto';
import { QuestionSectionDto } from '../model/exam/question-section-dto';
import { CandidateExamStateDto } from '../model/exam/state/candidate-exam-state-dto';
import { QuestionSectionStateDto } from '../model/exam/state/question-section-state-dto';
import { QuestionStateDto } from '../model/exam/state/question-state-dto';
import { APIResponse } from '../model/request/APIResponse';
import { ConnectionService } from './connection.service';
import { ExamService } from './exam.service';

@Injectable({
	providedIn: 'root'
})
export class ExamNavigationService {

	public examState: CandidateExamStateDto = new CandidateExamStateDto();
	public currentQuestionIndex: number = 0;

	private currentQuestionSource: Subject<QuestionStateDto> = new Subject<QuestionStateDto>();
	public currentQuestionChange$ = this.currentQuestionSource.asObservable();

	private flaggedQuestionsSource: Subject<number[]> = new Subject<number[]>();
	public flaggedQuestionsChange$ = this.flaggedQuestionsSource.asObservable();

	private answeredQuestionsSource: Subject<AnsweredQuestion> = new Subject<AnsweredQuestion>();
	public answeredQuestionsChange$ = this.answeredQuestionsSource.asObservable();

	constructor(private keyValueDataService: KeyValueDataService,
				private candidateQuestionDataService: CandidateQuestionDataService,
				private questionSectionDataService: QuestionSectionDataService,
				private connectionService: ConnectionService,
				private examService: ExamService) { }

	public getSubjectName(): Observable<string> {
		return this.keyValueDataService.getSubjectName();
	}

	public getScheduledFinish(): Observable<Date> {
		return this.keyValueDataService.getScheduledFinish();
	}

	public getCurrentQuestion(): Observable<QuestionStateDto> {
		return this.candidateQuestionDataService.getCurrentQuestionState();
	}

	public goToQuestion(questionNumber: number): Observable<boolean> {
		return this.candidateQuestionDataService.getQuestionByOrderIndex(questionNumber)
			.pipe(mergeMap((candidateQuestionDto: CandidateQuestionDto) => this.goToQuestionOrSectionModal(candidateQuestionDto)));
	}

	public goToNextQuestion(): Observable<boolean> {
		return this.candidateQuestionDataService.getCurrentQuestionState()
			.pipe(mergeMap((questionState: QuestionStateDto) => this.candidateQuestionDataService.getQuestionByOrderIndex(questionState.question.orderIndex + 1)))
			.pipe(mergeMap((candidateQuestionDto: CandidateQuestionDto) => this.goToQuestionOrSectionModal(candidateQuestionDto)));
	}

	public goToPreviousQuestion(): Observable<boolean> {
		return this.candidateQuestionDataService.getCurrentQuestionState()
			.pipe(mergeMap((questionState: QuestionStateDto) => this.candidateQuestionDataService.getQuestionByOrderIndex(questionState.question.orderIndex - 1)))
			.pipe(mergeMap((candidateQuestionDto: CandidateQuestionDto) => this.goToQuestionOrSectionModal(candidateQuestionDto)));
	}

	public getQuestionCount(): Observable<CandidateQuestionDto[]> {
		return this.candidateQuestionDataService.getAllQuestions();
	}

	public getQuestionSectionsWithCandidateQuestions(): Observable<QuestionSectionStateDto[]> {
		return this.questionSectionDataService.getSectionQuestions();
	}

	public flagCurrentQuestion(): void {
		this.candidateQuestionDataService.flagCurrentQuestion()
			.pipe(mergeMap(() => this.getFlaggedQuestionNumbers()))
			.pipe(map((flaggedNumbers: number[]) => this.flaggedQuestionsSource.next(flaggedNumbers)))
			.pipe(first())
			.subscribe();
	}

	public setAnsweredCurrentQuestion(question: AnsweredQuestion): void {
		this.answeredQuestionsSource.next(question);
	}

	public getFlaggedQuestionNumbers(): Observable<number[]> {
		return this.candidateQuestionDataService.getFlaggedQuestionNumbers();
	}


	public getLastQuestion(): Observable<QuestionStateDto> {
		return this.candidateQuestionDataService.getLastQuestionState();
	}

	private goToQuestionOrSectionModal(question: CandidateQuestionDto): Observable<boolean> {

		return forkJoin({
			questionSection: this.questionSectionDataService.get(question.sectionId),
			currentSectionNumber: this.keyValueDataService.getCurrentSectionNumber()
		})
		.pipe(mergeMap((data: { questionSection: QuestionSectionDto, currentSectionNumber: number }) => {

			if (data.questionSection.orderIndex < data.currentSectionNumber) {
				return of(false);
			}

			if (data.questionSection.orderIndex === data.currentSectionNumber) {
				return of(true);
			}

			if (data.questionSection.orderIndex > data.currentSectionNumber) {
				return of(false);
			}

			return of(true);
		}));
	}

	public updateCurrentQuestion(question: QuestionStateDto): Observable<APIResponse<void>> {
		const newQuestionIndex: number = question.question.orderIndex;

		return this.keyValueDataService.setCurrentQuestionNumber(question.question.orderIndex)
			.pipe(map(() => this.currentQuestionSource.next(question)))
			.pipe(mergeMap(() => iif(() => this.connectionService.isOnline(),
										this.examService.updateCurrentQuestionNumber(newQuestionIndex),
										of())));
	}
}
