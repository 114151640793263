<h3 mat-dialog-title>Go to next section</h3>
<mat-dialog-content>
  <div class="row mb-2">
    <div class="col d-flex justify-content-center">
      <h4>Are you sure you want to move onto the next section?</h4>
    </div>
  </div>
  @if (questions && (questions.flagged.length || questions.nonAttempted.length)) {
    <mat-divider></mat-divider>
    <div class="row mb-3 mt-3">
      @if (questions.flagged.length) {
        <div class="col button-column-container text-center">
          @for (question of questions.flagged; track question) {
            <div class="button-container mb-2">
              <button mat-raised-button class="tbp" (click)="goToQuestion(question.orderIndex)">Go To Question {{ question.orderIndex }}</button><br>
            </div>
          }
          <h4>Flagged Questions</h4>
        </div>
      }
      @if (questions.nonAttempted.length) {
        <div class="col button-column-container text-center">
          @for (question of questions.nonAttempted; track question) {
            <div class="button-container mb-2">
              <button mat-raised-button class="tbp" (click)="goToQuestion(question.orderIndex)">Go To Question {{ question.orderIndex }}</button><br>
            </div>
          }
          <h4>Unanswered Questions</h4>
        </div>
      }
    </div>
    <mat-divider></mat-divider>
  }
  <div class="row mt-2">
    <div class="col d-flex justify-content-center">
      <h5 class="text-center">Once you transition to the next section<br><strong>you will not be able to return</strong></h5>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row w-100 no-gutters">
    <div class="col d-flex justify-content-between">
      <button mat-raised-button class="tbp" (click)="close()">Stay on current section</button>
      <button mat-raised-button class="tbs" (click)="navigateSection()">Go to next section</button>
    </div>
  </div>
</mat-dialog-actions>
