import { Injectable } from "@angular/core";
import { MathsTool } from "../site/exam/toolbars/maths-toolbar/classes/maths-tool";
import { Subject } from "rxjs";
import { ExamToolIdentifier } from "../site/exam/toolbars/maths-toolbar/classes/tool.enum";


@Injectable({
    providedIn: 'root'
})
export class ExamToolsService {

	private currentToolSource: Subject<MathsTool> = new Subject<MathsTool>();
	public currentToolChange$ = this.currentToolSource.asObservable();

	public tools: MathsTool[] = [{
		name: 'ruler',
		enabled: false,
		type: ExamToolIdentifier.Ruler
	},
	{
		name: 'protractor',
		enabled: false,
		type: ExamToolIdentifier.Protractor
	}];

	public showHideTool(type: ExamToolIdentifier): void {
		const tool = this.tools.find(x => x.type === type);

		if (tool) {
			tool.enabled = !tool.enabled;
			this.currentToolSource.next(tool);
		}
	}

	public isToolEnabled(type: ExamToolIdentifier): boolean {
		const tool = this.tools.find(x => x.type === type);

		return tool!.enabled ?? false;
	}
}
