import { ExamPauseDTO } from "../exam-pause-dto";
import { ITimedBlock } from "../timed-block-interface";
import { QuestionSectionStateDto } from "./question-section-state-dto";

export class CandidateExamStateDto {
	public duration!: number;
	public candidateName!: string;
	public subjectType!: string;
	public subjectName!: string;
	public sections!: QuestionSectionStateDto[];
	public stateIdentifier!: number;
	public examCodeChecksum!: string;
	public currentBlockIndex!: number;
	public currentQuestionNumber!: number;
	public currentSectionNumber!: number;
	public timedBlocks!: ITimedBlock[];
	public pause!: ExamPauseDTO;
	public canNavigateAway!: boolean;
}
