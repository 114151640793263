import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITimedBlock } from '../../model/exam/timed-block-interface';
import { TimedBlockRepository } from '../repositories/timed-blocks-repository.service';

@Injectable({
	providedIn: 'root'
})
export class TimedBlockDataService {
	constructor(private timedBlockRepository: TimedBlockRepository) { }

	public getAllBlocks(): Observable<ITimedBlock[]> {
		return this.timedBlockRepository.getAll()
			.pipe(map((val) => val.map(this.timedBlockRepository.convertDto)));
	}
}
