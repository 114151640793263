import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '../../../../service/dialog.service';
import { ConnectionService } from '../../../../service/connection.service';
import { KeyValueDataService } from '../../../../database/services/key-value-data.service';

@Component({
	selector: 'app-header',
	templateUrl: './exam-header.component.html',
	styleUrls: ['./exam-header.component.scss']
})
export class ExamHeaderComponent implements OnInit{
	@Input() public text!: string;
	@Input() public enableTimer: boolean = true;
	@Input() public showConnectionIndicator: boolean = true;
	@Input() public showProgressBar: boolean = true;
	@Input() public isExam: boolean = false;
	@Input() public isPausePage: boolean = false;
	public learnerName?: string;

	@Output() public timerAction: EventEmitter<string> = new EventEmitter<string>();

	constructor(private connectionService: ConnectionService, private dialogService: DialogService, private keyValueDataService: KeyValueDataService) { }

	public ngOnInit(): void {
		if (!this.isPausePage) {
			this.keyValueDataService.getLearnerName().subscribe((learnerName: string) => {
				this.learnerName = learnerName;
			});
		}
	}

	public isOnline(): boolean {
		return this.connectionService.isOnline();
	}

	public isOffline(): boolean {
		return this.connectionService.isOffline();
	}

	public showThemesModal(): void {
		this.dialogService.showThemeSelectionModal();
	}

	public timerRedirect(url: string): void {
		this.timerAction.emit(url);
	}
}
