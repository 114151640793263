import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SynchroniseService } from '../../../../service/synchronise.service';
import { TimeService } from '../../../../service/time.service';
import { ExamLogDataService } from '../../../../database/services/exam-log-data.service';
import { ExamLogType } from '../../../../model/exam/log/exam-log-type.enum';

@Component({
	selector: 'app-end-confirm',
	templateUrl: './end-confirm.component.html',
	styleUrls: ['./end-confirm.component.scss']
})
export class EndConfirmComponent implements OnDestroy {

	public subscriptions: Subscription[] = [];

	constructor(private dialogRef: MatDialogRef<EndConfirmComponent>,
		private router: Router,
		private timeService: TimeService,
		private synchroniseService: SynchroniseService,
		private examLogDataService: ExamLogDataService) { }

	public ngOnDestroy(): void {
		this.subscriptions.forEach(x => x && x.unsubscribe());
	}

	public goBackToExam(): void {
		this.router.navigateByUrl('/exam');
		this.dialogRef.close();
	}

	public endExam(): void {
		this.subscriptions.push(
				this.synchroniseService.calculateChecksum()
				.subscribe(() => {
					this.router.navigateByUrl('/exam/finish');
					this.dialogRef.close();
				}));
	}
}
