import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../../service/theme.service';
import { Theme } from '../../../theme/theme.interface';

@Component({
  selector: 'app-theme-selector-modal',
  templateUrl: './theme-selector-modal.component.html',
  styleUrls: ['./theme-selector-modal.component.scss']
})
export class ThemeSelectorModalComponent implements OnInit {
  public themes: Theme[] = [];

  constructor(private themeService: ThemeService, private dialogRef: MatDialogRef<ThemeSelectorModalComponent>) { }

  public ngOnInit(): void {
    this.themes = this.themeService.getAvailableThemes();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public setTheme(theme: Theme): void {
    this.themeService.setActiveTheme(theme);
  }
}
