import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SaveService {
    private saving: boolean = false;

    private isSavingSource: Subject<boolean> = new Subject<boolean>();
	public isSavingChange$ = this.isSavingSource.asObservable();

    constructor(private snackBar: MatSnackBar) {}

    public showSaveSnackbar(isAutoSave: boolean = false): void {
        if (!isAutoSave) {
            this.saving = true;
            this.isSavingSource.next(this.saving);
        }

        this.snackBar.open(isAutoSave ? 'Autosaving...' : 'Saving...', undefined, {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: 'saving-snackbar'
        });
    }

    public hideSaveSnackbar(): void {
        this.snackBar.dismiss();
        this.saving = false;
        this.isSavingSource.next(this.saving);
    }

    public isSaving(): boolean {
        return this.saving;
    }
}
