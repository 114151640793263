import { Injectable } from '@angular/core';
import { AppDatabase } from '../app.database';
import { AssetEntity } from '../entities/asset-entity';
import { BaseRepository } from './base-repository';
import { QuestionAssetDTO } from '../../model/exam/question-asset-dto';

@Injectable({
	providedIn: 'root'
})
export class AssetRepositoryService extends BaseRepository<AssetEntity, string, QuestionAssetDTO> {

	constructor(protected override db: AppDatabase) {
		super(db, 'assets');
	}

	public convertDto(entity: AssetEntity): QuestionAssetDTO {
		return {
			id: entity.assetId,
			candidateQuestionId: entity.candidateQuestionId,
			questionAssetTypeId: entity.questionAssetTypeId,
			name: entity.name,
			url: entity.url,
			layout: entity.layout,
			layoutTypeIdentifier: entity.layoutTypeIdentifier,
			assetTypeIdentifier: entity.assetTypeIdentifier,
			labelText: entity.labelText,
			labelAlignmentIdentifier: entity.labelAlignmentIdentifier,
			labelPositionIdentifier: entity.labelPositionIdentifier
		};
	}
	public convertEntity(dto: QuestionAssetDTO): AssetEntity {
		return {
			assetId: dto.id,
			candidateQuestionId: dto.candidateQuestionId,
			questionAssetTypeId: dto.questionAssetTypeId,
			name: dto.name,
			url: dto.url,
			layout: dto.layout,
			layoutTypeIdentifier: dto.layoutTypeIdentifier,
			assetTypeIdentifier: dto.assetTypeIdentifier,
			labelText: dto.labelText,
			labelAlignmentIdentifier: dto.labelAlignmentIdentifier,
			labelPositionIdentifier: dto.labelPositionIdentifier
		};
	}
}
